import iconQrcode from '../assets/icons/qr_code_download_app.svg';
import iconPlayStore from '../assets/icons/ic_play_store.svg';
import iconAppleStore from '../assets/icons/ic_app_store.svg';
import React from 'react';

export const DownloadQrcode = (): any => {
    return (
        <React.Fragment>
            <header className="pr-5">
                <h4 className="layout-title font-title--bold font-title--sm font-h4">
                    <strong>Aponte a câmera do celular para o QR code e baixe o aplicativo da Toro</strong>.
                </h4>
            </header>
            <label className="font-label">Se preferir, acesse o link da loja de aplicativos.</label>
            <div className="main-container align-items-center justify-content-center">
                <img src={iconQrcode} alt="QRCODE" className="img-qrcode" />
            </div>
            <div className="btn-app">
                <a
                    className="download-play"
                    href="https://play.google.com/store/apps/details?id=br.com.toroinvestimentos"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={iconPlayStore} alt="play store" />
                </a>
                <a
                    className="download-store"
                    href="https://apps.apple.com/br/app/toro-investimentos/id1490105579"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={iconAppleStore} alt="apple store" />
                </a>
            </div>
        </React.Fragment>
    );
};
