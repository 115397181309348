import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IModalProps {
    open: boolean;
    icon?: string | React.ReactNode;
    label?: string;
    template?: string | React.ReactNode;
    close: any;
}

const useStyles = makeStyles(() => ({
    paper: {
        overflowY: 'unset'
    },
    customizedButton: {
        position: 'absolute',
        left: '95%',
        top: '-9%',
        backgroundColor: 'transparent',
        color: 'gray'
    }
}));

export function FullScreenDialog(props: IModalProps) {
    const classes = useStyles();

    return (
        <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col col-lg-5">
                        <Toolbar>
                            {props.icon}
                            <IconButton
                                className={classes.customizedButton}
                                edge="start"
                                color="inherit"
                                onClick={props.close}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>

                        {props.template}

                        <button
                            className="btn btn-primary btn--md btn--full btn--solid btn-primary--solid"
                            type="button"
                            onClick={props.close}
                            aria-label="close"
                        >
                            Fechar
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
