/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { isValidCNPJ, isValidCPF, isValidEmail } from '@brazilian-utils/brazilian-utils';

import { Layout } from '../../layout';

import { login } from '../../services/toro-oidc';
import { useQuery } from '../../hooks/useQuery';

export function Login() {
    const { op_it, _d: details } = useQuery({ op_it: '', _d: {} });

    const [currentToroSession, setCurrentToroSession] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [redirectUrl, setRedicrectUrl] = useState('');
    const redirectForm = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (redirectUrl && redirectForm.current) {
            redirectForm.current.submit();
        }
    }, [redirectUrl, redirectForm]);

    const navigate = useNavigate();
    const location = useLocation();
    const termLink = details?.client?.term_link;
    const pageTitle = 'Use seus dados da Toro para acessar a plataforma.';

    const validateInformationReturn = () => {
        if (details.params?.scope?.includes('cpfCnpj')) {
            return 'CPF, nome, e-mail, data de nascimento, gênero, endereço e conta na corretora';
        } else {
            return 'nome, e-mail, data de nascimento, gênero, endereço e conta na corretora';
        }
    };

    return (
        <Layout uid={op_it} title={pageTitle} message={errorMessage}>
            <Formik
                initialValues={{ username: '', password: '' }}
                validationSchema={yup.object().shape({
                    username: yup
                        .string()
                        .test('is-username', 'Email ou CPF inválidos', (username = '') => {
                            return isValidCPF(username) || isValidEmail(username) || isValidCNPJ(username);
                        })
                        .required('Email ou CPF inválidos'),
                    password: yup.string().required('Senha inválida')
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    const { username, password } = values;
                    try {

                        const response = await login(op_it, username, password);

                        setErrorMessage('');

                        if (response?.redirected) {
                            setRedicrectUrl(response?.location);
                        }
                    } catch (error: any) {
                        if (error.isAxiosError) {
                            const toroSession = error.response?.data?.toroSession;

                            if (toroSession && !currentToroSession) {
                                setCurrentToroSession(error.response?.data.toroSession);
                            }

                            switch (error.response?.data?.errorCode) {
                                case 'MFA_NEEDED':
                                    navigate(
                                        {
                                            pathname: '/login/mfa',
                                            search: location.search,
                                        },
                                        {
                                            state: {
                                                username,
                                                password,
                                                lastFour: error.response?.data?.lastFourDigitsPhone,
                                                hasDevice: error.response?.data?.hasDevice,
                                                toroSession: currentToroSession || toroSession
                                            }
                                        }
                                    );
                                    break;
                                case 'INVALID_CREDENTIALS':
                                    setErrorMessage(error.response?.data.message);
                                    break;
                                default:
                                    navigate({
                                        pathname: '/login/error',
                                        search: `${location.search}&${error.response?.data.errorCode || 'UNEXPECTED'}`
                                    });
                                    break;
                            }
                        }
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting }) => {
                    const showUsernameError = errors.username && touched.username;
                    const showPasswordError = errors.password && touched.password;

                    return (
                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <div className={`input-field ${showUsernameError ? 'input-error' : ''}`}>
                                    <label htmlFor="username">E-mail ou CPF</label>
                                    <input
                                        required
                                        type="text"
                                        name="username"
                                        className="form-input form-field--js "
                                        autoCapitalize="none"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.username}
                                        disabled={isSubmitting}
                                    />
                                    {showUsernameError && (
                                        <span className="input-error-message">{errors.username}</span>
                                    )}
                                </div>
                            </div>
                            <div className="form-group">
                                <div className={`input-field ${showPasswordError ? 'input-error' : ''}`}>
                                    <label htmlFor="password">Senha</label>
                                    <input
                                        required
                                        type="password"
                                        name="password"
                                        className="form-input form-field--js"
                                        autoCapitalize="none"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        disabled={isSubmitting}
                                    />

                                    {showPasswordError && (
                                        <span className="input-error-message">{errors.password}</span>
                                    )}
                                </div>
                            </div>

                            <div className="mt-1 mb-4">
                                <a
                                    className="font-text--medium link link-primary"
                                    href="https://app.toroinvestimentos.com.br/esqueci-minha-senha"
                                >
                                    Esqueceu a senha?
                                </a>
                            </div>
                            <p>
                                Para continuar, a Toro vai compartilhar com a plataforma o seu{' '}
                                {validateInformationReturn()}.
                                <span style={{ display: termLink ? 'inline' : 'none' }}>
                                    {' Veja o '}
                                    <a
                                        className="font-text--medium link link-primary"
                                        href=""
                                        onClick={() => navigate('/login/term', { state: { termLink } })}
                                    >
                                        Termo de Contratação
                                    </a>
                                    .
                                </span>
                            </p>

                            <button
                                type="submit"
                                className="btn btn-primary btn--md btn--full btn--solid btn-primary--solid"
                                disabled={isSubmitting}
                            >
                                Autorizar e entrar
                            </button>

                            <div className="mt-4">
                                Ainda não tem conta na Toro?{' '}
                                <a
                                    className="font-text--medium link link-primary"
                                    href="https://cadastro.toroinvestimentos.com.br/"
                                >
                                    Cadastre-se grátis.
                                </a>
                            </div>
                        </form>
                    );
                }}
            </Formik>
            <form ref={redirectForm} action={redirectUrl} method="get"></form>
        </Layout>
    );
}
