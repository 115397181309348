import { Layout } from '../../layout';
import { useQuery } from '../../hooks/useQuery';
import { useLocation } from 'react-router-dom';

export function Term() {
    const { op_it } = useQuery({ op_it: '', _d: {} });

    const location = useLocation();
    const termLink = location?.state?.termLink;

    return (
        <Layout uid={op_it} backAction expandContent fullPageLayout>
            <iframe title="Terms" className="iframe-term" src={termLink} />
        </Layout>
    );
}
