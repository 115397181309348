import React from 'react';

import { Layout } from '../../layout';
import { useNavigate, useLocation } from 'react-router-dom';

import warningIcon from '../../assets/icons/warning.svg';
import { useQuery } from '../../hooks/useQuery';

export function UnexpectedError() {
    const { op_it } = useQuery({ op_it: '' });
    const navigate = useNavigate();
    const location = useLocation();

    const handleRetryLogin = () => {
        navigate({
            pathname: '/login',
            search: location.search
        });
    };

    return (
        <Layout uid={op_it} showLogo={false}>
            <div className="mt-3 mb-3">
                <img src={warningIcon} alt="Erro inesperado" />
            </div>
            <div className="mt-4 mb-4">
                <h2>Estamos com uma dificuldade no momento.</h2>
            </div>

            <div className="mt-3 mb-3">
                <p>Nossa equipe está trabalhando para corrigr o problema o quanto antes.</p>
            </div>

            <div className="mt-3 mb-3">
                <p>
                    <strong>Precisa de ajuda? Fale com a gente:</strong>
                    <br />
                    contato@toroinvestimentos.com.br
                </p>
            </div>

            <div className="row justify-content-center mt-5 pt-5 pr-2 pl-2">
                <button type="button" className="btn btn-outline-primary btn-block" onClick={handleRetryLogin}>
                    Tentar login novamente
                </button>
            </div>
        </Layout>
    );
}
