import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Login } from './pages/Login';
import { MFA } from './pages/MFA';
import { Term } from './pages/Term';
import { Consent } from './pages/Consent';
import { NotFound } from './pages/NotFound';
import { Error } from './pages/Error';

export default function Routess() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/login/consent" element={<Consent />} />
                <Route path="/login/mfa" element={<MFA />} />
                <Route path="/login/term" element={<Term />} />
                <Route path="/login/error" element={<Error />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
}
