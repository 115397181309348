import React from 'react';
import { useNavigate } from 'react-router-dom';

import toroLogo from '../assets/images/toro-small.svg';
import closeIcon from '../assets/icons/close.svg';

export type LayoutProps = {
    children?: React.ReactNode;
    title?: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    message?: string;
    uid: string;
    showLogo?: boolean;
    backAction?: boolean;
    expandContent?: boolean;
    fullPageLayout?: boolean;
};

export function Layout({
    children,
    title,
    subtitle,
    message,
    uid,
    showLogo = true,
    backAction = false,
    expandContent = false,
    fullPageLayout = false
}: LayoutProps) {
    const navigate = useNavigate();

    const headerAlignment = showLogo ? 'justify-content-between' : 'justify-content-end';
    const contentWide = expandContent ? 'col-lg-12' : 'col-lg-5';
    const fullPageLayoutClass = fullPageLayout ? ' full-page-layout' : '';
    return (
        <div className="container">
            <div id="header" className={`row p-3 align-items-center ${headerAlignment}`}>
                {showLogo && <img className="toro-logo" src={toroLogo} alt="Logo Toro Investimentos" />}
                {!backAction ? (
                    <a className="icon round" href={`/oauth/interaction/${uid}/abort`}>
                        <img src={closeIcon} alt="Abort authentication" />
                    </a>
                ) : (
                    <div className="icon round">
                        <img onClick={() => navigate(-1)} src={closeIcon} alt="Back to Previous Page" />
                    </div>
                )}
            </div>

            <div className={`container${fullPageLayoutClass}`}>
                {!!title && (
                    <div className="row align-items-center justify-content-center">
                        <div className="col col-lg-5">
                            <header className="pr-5">
                                <h4 className="layout-title font-title--bold font-title--sm">{title}</h4>
                            </header>
                        </div>
                    </div>
                )}

                {!!subtitle && (
                    <div className="row align-items-center justify-content-center">
                        <div className="col col-lg-5">
                            <header className="pr-5">
                                <p className="layout-subtitle font-silver">{subtitle}</p>
                            </header>
                        </div>
                    </div>
                )}
                <div className="row align-items-center justify-content-center">
                    <div className={`col ${contentWide}`}>
                        {!!message && <p className="form-error-message">{message}</p>}
                        <div className="layout-content">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
